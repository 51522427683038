import axios from "axios";
import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
// import deposits from "../../app/components/transaction/Deposits";
import css from "../css/Addcase.module.css";
import { Link } from 'react-router-dom';
//import Rightcontainer from "../Components/Rightcontainer";
import '../css/Loader.css'
import Swal from "sweetalert2";
// import findGif from "/";
import Header from "../Components/Header";
import ModalVideo from 'react-modal-video';

const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
const nodeMode = process.env.NODE_ENV;
if (nodeMode === "development") {
  var baseUrl = beckendLocalApiUrl;
} else {
  baseUrl = beckendLiveApiUrl;
}
const Kyc = () => {

  const history = useHistory();

  return (
    <>
        <div className="leftContainer bg-white" style={{ minHeight: '100vh', height: '100%', paddingTop: '60px' }}>
<center>
              <div className="mt-4">

                                <Link
                  className="gameCard-container"
                  to={`/Kyc3`}
                >

                <div
                    className="add-fund-box mt-3" style={{ paddingTop: "0px", height: "60px",width:"90%" }}>
                  <div
                    className="d-flex align-items-center"
                    style={{
                      backgroundColor: "#fafafa",
                      border: "1px solid #e0e0e0",
                      borderRadius: "7px",
                    }}
                  >
                    <div
                      className="d-flex align-items-center"
                      style={{ height: "60px", display: "flex", textAlign: "center" }}
                    >
                      <img
                        width="40px"
                        src="Images/ekyc.jpeg"
                        alt=""
                        style={{
                          marginLeft: "7px",
                          paddingBottom: "10px",
                          paddingLeft: "3px",
                          paddingTop: "5px",
                        }}
                      />
                    </div>
                    <div className="d-flex justify-content-center flex-column ml-4">
                      <div className="jss30">
                        <strong>Aadhar OTP <span style={{color:"green"}}> (Instant)</span></strong>
                      </div>
                      <div className="jss31"></div>
                    </div>
                  </div>
                  
                </div>
                </Link>
                                <Link
                  className="gameCard-container"
                  to={`/Kyc2`}
                >

                <div
                    className="add-fund-box mt-3" style={{ paddingTop: "0px", height: "60px",width:"90%" }}>
                  <div
                    className="d-flex align-items-center"
                    style={{
                      backgroundColor: "#fafafa",
                      border: "1px solid #e0e0e0",
                      borderRadius: "7px",
                    }}
                  >
                    <div
                      className="d-flex align-items-center"
                      style={{ height: "60px", display: "flex", textAlign: "center" }}
                    >
                      <img
                        width="40px"
                        src="Images/aadhar.svg"
                        alt=""
                        style={{
                          marginLeft: "7px",
                          paddingBottom: "10px",
                          paddingLeft: "3px",
                          paddingTop: "5px",
                        }}
                      />
                    </div>
                    <div className="d-flex justify-content-center flex-column ml-4">
                      <div className="jss30">
                        <strong>Aadhar Manually</strong>
                      </div>
                      <div className="jss31"></div>
                    </div>
                  </div>
                  
                </div>
                </Link>
                
                </div>

</center>

</div>
    </>
  );
};
export default Kyc;